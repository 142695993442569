import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';
import { Link } from '~/components/elements/Link';
import { LogosInner } from '~/components/flexible/Logos';
import Date from '~/components/elements/Date';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Hero = ({
  background,
  image,
  imageFirst,
  preHeading,
  heading,
  headingSize,
  content,
  button,
  maxTextWidth,
  logo,
  logoItems,
  imageSize,
  noMobPad,
}) => {
  let bgClasses = '';
  if (background) {
    bgClasses = 'container-lg lg:px-16 section-sm rounded-lg ' + background;
  } else {
    bgClasses = 'container';
  }
  let hSize = 'text-h1';
  if (headingSize) {
    hSize = headingSize;
  }
  return (
    <header className={background ? 'section pt-0 !pb-0' : 'section'}>
      <div className={bgClasses}>
        <HeroInner
          image={image}
          imageFirst={imageFirst}
          preHeading={preHeading}
          heading={heading}
          headingSize={hSize}
          maxTextWidth={maxTextWidth}
          content={content}
          button={button}
          imageSize={imageSize}
          logo={logo}
          noMobPad={noMobPad}
        />
      </div>
    </header>
  );
};

export const HeroInner = ({
  image,
  imageFirst,
  preHeading,
  heading,
  headingSize = 'text-h1',
  content,
  button,
  maxTextWidth,
  maxImageWidth,
  imageSize,
  logo,
  link,
  date,
  noMobPad,
  flipColumnsOnMobile,
}) => {
  let rowOrder = 'md:flex-row-reverse';
  let imageAnim = '50';
  if (imageFirst === true) {
    rowOrder = '';
    imageAnim = '-50';
  }
  let largeCol = 'w-full',
    mediumCol = 'md:w-1/2',
    smallCol = 'w-full',
    textCol,
    imageCol;
  imageCol = largeCol;
  textCol = smallCol;
  if (imageSize === 'small') {
    imageCol = smallCol;
    textCol = largeCol;
  }
  if (imageSize === 'medium') {
    imageCol = mediumCol;
    textCol = mediumCol;
  }
  let mobilePadding = [];
  if (noMobPad) {
    mobilePadding.push('px-0');
  } else {
    mobilePadding.push('px-5');
  }

  return (
    <InViewAnimator
      className={
        mobilePadding +
        ` md:px-0 flex flex-col md:flex-row gap-6 ${
          flipColumnsOnMobile ? 'flex-col-reverse' : ''
        } md:gap-[43px] items-center ` +
        rowOrder
      }
      inner={(controls) => (
        <>
          {image && (
            <InViewAnimatorBasicItem
              controls={controls}
              delay={0.45}
              prop=""
              start={imageAnim}
              className={imageCol + ' rounded-lg overflow-hidden relative'}
              style={{ maxWidth: `${maxImageWidth}px` }}
            >
              <Image image={image} className="relative z-1" />
              {logo && (
                <div className="max-w-[100px] lg:max-w-[140px] absolute bottom-[8%] left-[8%] lg:bottom-11 lg:left-11 bg-white px-5 py-3 rounded-sm">
                  <Image image={logo} />
                </div>
              )}
            </InViewAnimatorBasicItem>
          )}
          <InViewAnimatorBasicItem
            className={textCol}
            style={{ maxWidth: `${maxTextWidth}px` }}
            controls={controls}
            delay={0.2}
            prop="y"
            start={50}
          >
            <div
              className={
                'text-center md:text-left ' +
                (imageSize === 'small' ? 'max-w-[630px] md:mx-auto' : 'max-w-md sm:mx-auto')
              }
            >
              {preHeading && (
                <p className="mb-5">
                  <span className="font-bold md:text-[17px] text-forest">{preHeading}</span>
                  {date && (
                    <span>
                      {' '}
                      • 
                      <Date dateString={date} />
                    </span>
                  )}
                </p>
              )}
              <h1 className={'mb-5 ' + headingSize}>{heading}</h1>
              {content && <div className="max-w-lg t-19 " dangerouslySetInnerHTML={{ __html: content }} />}
              {button && (
                <Button
                  className="mt-6 md:mt-[42px] w-full max-w-xs"
                  link={{ url: button.url, title: button.title }}
                  type="button"
                  color="forest"
                />
              )}
              {link && (
                <Link faux className="text-lg font-bold text-forest lg:mt-5" title={'Read more'} url={link} iconAfter />
              )}
            </div>
          </InViewAnimatorBasicItem>
        </>
      )}
    />
  );
};
