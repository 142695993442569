import React from 'react';
import { Link } from '~/components/elements/Link';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const IconGrid = ({ preHeading, heading, content, link, gridItems, centred, background, textLrg, listMaxWidth }) => {
  let containerClasses = [''];
  if (background) {
    containerClasses.push('container-lg section-md bg-pampas rounded-lg ' + background);
  } else {
    containerClasses.push('container');
  }
  if (centred) {
    containerClasses.push('text-center');
  }
  let headingSize = 'text-h3';
  if (centred) {
    headingSize = 'text-h4';
  }
  return (
    <div className={containerClasses.join(' ')}>
      <header className={centred ? 'max-w-md mx-auto' : 'max-w-[620px]'}>
        {preHeading && <p className="mb-4 font-bold text-forest">{preHeading}</p>}
        {heading && <h3 className={'mb-3 ' + headingSize}>{heading}</h3>}
        <p className="mb-3 text-lg prose" dangerouslySetInnerHTML={{ __html: content }} />
        {link && <Link className={'mt-4 font-bold text-forest'} title={link.title} iconAfter url={link.url} />}
      </header>
      {gridItems && (
        <InViewAnimator className={`mt-11 space-y-8 md:space-y-0 md:flex md:gap-12 lg:gap-24 ${listMaxWidth ? 'max-w-[1044px] mx-auto lg:mt-20' : ''}`}
        inner={(controls) => (
          <>
          {gridItems.map((listItem, i) => (
            <InViewAnimatorBasicItem key={i} className="block text-center md:w-1/3 md:text-left"
              controls={controls}
              delay={i/6}
              duration={1.5}
            >
              {listItem.image && (
                <figure
                  className={
                    'mb-4 h-[75px] w-[98px] flex items-center' + (centred ? 'justify-center mx-auto' : 'justify-start')
                  }
                >
                  <Image image={listItem.icon} className="w-auto h-auto max-w-full max-h-full" />
                </figure>
              )}
              {listItem.icon && (
                <figure
                  className={`${textLrg ? 'w-[98px] h-[75px]' :'w-[118px] h-[90px] mx-auto md:mx-0'} mb-3 md:mb-4 ${
                    centred ? 'justify-center mx-auto' : 'justify-start'
                  }`}
                >
                  <Image image={listItem.icon} className="w-auto h-auto max-w-full max-h-full" />
                </figure>
              )}
              <p className={` ${centred ? 'lg:text-lg mb-4 mx-auto max-w-[278px]' : 'text-lg max-w-lg'} ${textLrg ? 'lg:!text-xl' : ''}`}>
                {listItem.heading && <span className="font-semibold font-heading">{listItem.heading}&nbsp;</span>}
                {listItem.content && <span>{listItem.content}</span>}
              </p>
            </InViewAnimatorBasicItem>
          ))}
          </>
          )}
        />
      )}
    </div>
  );
};
