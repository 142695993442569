import React from 'react';
import { Link } from '~/components/elements/Link';
import { Image } from '~/components/elements/Image';
import { Icon, IconType } from '~/components/elements/Icon';

export const CustomersPostsList = ({ listItems, columns, filters, headingClasses, heading, categories, date }) => {
  let cols = 'sm:grid grid-cols-2';
  if (columns === '3') {
    cols = 'sm:grid grid-cols-2 lg:grid-cols-3';
  }
  let headingMargin = '';
  if (!filters) {
    headingMargin = 'mb-7';
  }
  return (
    <section className="section">
      <div className="container">
        {heading && <h2 className={headingClasses + ' ' + headingMargin}>{heading}</h2>}
        {filters && (
          <form className="justify-between mt-5 md:flex">
            <div className="w-full md:w-auto md:flex">
              <fieldset className="relative w-full my-4 mr-4 md:w-auto">
                <select className="relative w-full px-4 py-3 leading-4 transition border rounded-md appearance-none z-1 bg-white border-periwinkle focus:border-mineral focus:outline-none">
                  <option value="" disabled selected>
                    Lender type
                  </option>
                  <option>Nulla porttitor </option>
                  <option>Lorem ipsum</option>
                  <option>Vivamus magna justo</option>
                </select>
                <i className="block fw-icon absolute z-3 right-4 top-[18px] text-forest w-[14px] h-[14px]">
                  <Icon type={IconType.ChevronDown} />
                </i>
              </fieldset>
              <fieldset className="relative w-full my-4 mr-4 md:w-auto">
                <select className="relative w-full px-4 py-3 leading-4 transition border rounded-md appearance-none bg-white z-1 border-periwinkle focus:border-mineral focus:outline-none">
                  <option value="" disabled selected>
                    Another option
                  </option>
                  <option>Nulla porttitor </option>
                  <option>Lorem ipsum</option>
                  <option>Vivamus magna justo</option>
                </select>
                <i className="block fw-icon absolute z-3 right-4 top-[18px] text-forest  w-[14px] h-[14px]">
                  <Icon type={IconType.ChevronDown} />
                </i>
              </fieldset>
            </div>
            <div className="w-full md:w-auto lg:w-96">
              <fieldset className="relative my-4">
                <input
                  type="search"
                  placeholder="Search for something"
                  className="relative w-full py-3 pl-10 pr-4 leading-4 transition border rounded-md appearance-none bg-white placeholder-deepsea z-1 border-periwinkle focus:border-mineral focus:outline-none"
                />
                <i className="block fw-icon absolute z-3 left-4 top-4 text-forest  w-[14px] h-[14px]">
                  <Icon type={IconType.Search} />
                </i>
              </fieldset>
            </div>
          </form>
        )}
        {listItems && (
          <ul className={`sm:grid ${cols} gap-6`}>
            {listItems.map((listItem, i) => (
              <ArticleItem key={i} {...listItem} />
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export const ArticleItem = ({ uri, title, postTypeCustomer }) => {
  let headingClasses = 'text-h5 text-forest';
  return (
    <li className="my-8">
      <a href={uri} title={title}>
        {postTypeCustomer.previewImage && (
          <figure className="mb-4 overflow-hidden rounded-md lg:mb-8 customer-ratio">
            <Image image={postTypeCustomer.previewImage} />
          </figure>
        )}
        <div className="max-w-lg">
          <h3 className={'mb-3 ' + headingClasses}>{title}</h3>
          <div className={'mb-3 text-[17px] lg:t-22'} dangerouslySetInnerHTML={{ __html: postTypeCustomer.previewContent }} />
          <Link faux className="text-lg font-bold text-forest" title={'Read story'} url={uri} iconAfter />
        </div>
      </a>
    </li>
  );
};
